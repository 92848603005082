import * as React from "react";
import * as ReactDOM from "react-dom";
import {Error} from "../components/Errors/Error";

ReactDOM.render(
  <Error status_code={ 404 }/>,
  document.getElementById('app')
);


